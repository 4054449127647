import React, { useState, useEffect } from 'react';
import Chatbot from './components/Chatbot';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from './theme';

const ChatButton = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: ${props => props.theme.colors.sendButtonBg};
    color: ${props => props.theme.colors.userMessageColor};
    border: none;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    &:hover {
        background: ${props => props.theme.colors.sendButtonBgHover};
    }
`;

const ChatApp = ({ apiKey }) => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [messages, setMessages] = useState(() => {
        const savedMessages = localStorage.getItem('chatMessages');
        return savedMessages ? JSON.parse(savedMessages) : [];
    });

    useEffect(() => {
        localStorage.setItem('chatMessages', JSON.stringify(messages));
    }, [messages]);

    const closeChat = () => {
        setIsChatOpen(false);
    };

    const openChat = () => {
        setIsChatOpen(true);
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                {isChatOpen ? (
                    <Chatbot isOpen={isChatOpen} closeChat={closeChat} messages={messages} setMessages={setMessages} apiKey={apiKey} />
                ) : (
                    <ChatButton onClick={openChat}>EAI</ChatButton>
                )}
            </div>
        </ThemeProvider>
    );
};

export default ChatApp;
