export const theme = {
    colors: {
        userMessageBg: '#0069d9',
        userMessageColor: '#fff',
        botMessageBg: '#e0e0e0',
        botMessageColor: '#000',
        inputBorder: '#e0e0e0',
        inputBgDisabled: '#e0e0e0',
        sendButtonBg: '#0069d9',
        sendButtonBgHover: '#005bb5',
        sendButtonBgDisabled: '#a0a0a0',
        typingIndicatorBg: '#e0e0e0',
        headerBg: '#0069d9',
        headerColor: '#fff',
    }
};
