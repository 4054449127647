import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const slideDown = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
`;

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 600px;
    width: 600px;
    background: #f4f7f9;
    border: 1px solid ${props => props.theme.colors.inputBorder};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
    animation: ${props => (props.isOpen ? css`${slideUp} 0.3s forwards` : css`${slideDown} 0.3s forwards`)};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.colors.headerBg};
    color: ${props => props.theme.colors.headerColor};
    padding: 10px;
    font-size: 1.2em;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

const CloseButton = styled.button`
    background: transparent;
    border: none;
    color: ${props => props.theme.colors.headerColor};
    font-size: 1.2em;
    cursor: pointer;
`;

const MessagesContainer = styled.div`
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

const Message = styled.div`
    background: ${props => (props.sender === 'user' ? props.theme.colors.userMessageBg : props.theme.colors.botMessageBg)};
    color: ${props => (props.sender === 'user' ? props.theme.colors.userMessageColor : props.theme.colors.botMessageColor)};
    padding: 10px 15px;
    border-radius: 15px;
    margin-bottom: 10px;
    max-width: 75%;
    align-self: ${props => (props.sender === 'user' ? 'flex-end' : 'flex-start')};
    white-space: pre-wrap; /* Mesajların düzgün görünmesi için */
`;

const InputContainer = styled.div`
    display: flex;
    padding: 10px;
    border-top: 1px solid ${props => props.theme.colors.inputBorder};
    background: #fff;
`;

const TextInput = styled.input`
    flex: 1;
    padding: 10px;
    border: 1px solid ${props => props.theme.colors.inputBorder};
    border-radius: 20px;
    margin-right: 10px;
    outline: none;
    &:disabled {
        background: ${props => props.theme.colors.inputBgDisabled};
    }
`;

const SendButton = styled.button`
    background: ${props => props.theme.colors.sendButtonBg};
    color: ${props => props.theme.colors.userMessageColor};
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    &:hover {
        background: ${props => props.theme.colors.sendButtonBgHover};
    }
    &:disabled {
        background: ${props => props.theme.colors.sendButtonBgDisabled};
        cursor: not-allowed;
    }
`;

const typingAnimation = keyframes`
    0% { transform: scale(0.8); opacity: 0.5; }
    100% { transform: scale(1); opacity: 1; }
`;

const TypingIndicator = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    align-self: flex-start;

    div {
        width: 8px;
        height: 8px;
        background: ${props => props.theme.colors.typingIndicatorBg};
        border-radius: 50%;
        margin: 0 2px;
        animation: ${typingAnimation} 0.8s infinite alternate;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }
        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
`;

const Chatbot = ({ isOpen, closeChat, messages, setMessages, apiKey }) => {
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const chatbotName = 'Encolay';

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const sendMessage = async () => {
        if (input.trim() === '') return;

        const newMessage = { text: input, sender: 'user' };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput('');
        setIsTyping(true);

        try {
            const response = await fetch(`http://localhost:11434/api/chat?apiKey=${apiKey}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model: "futurecode/encolay:latest",
                    messages: [
                        {
                            role: "user",
                            content: input,
                        }
                    ],
                    stream: false,
                }),
            });

            const data = await response.json();
            console.log(data); // API yanıtını kontrol edin

            const botMessage = { text: data.message.content, sender: 'bot' };
            setMessages(prevMessages => [...prevMessages, botMessage]);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsTyping(false);
            inputRef.current?.focus();
        }
    };

    return (
        <ChatContainer isOpen={isOpen}>
            <Header>
                {chatbotName}
                <CloseButton onClick={closeChat}>×</CloseButton>
            </Header>
            <MessagesContainer>
                {messages.map((msg, index) => (
                    <Message key={index} sender={msg.sender}>
                        {msg.text}
                    </Message>
                ))}
                {isTyping && (
                    <TypingIndicator>
                        <div></div>
                        <div></div>
                        <div></div>
                    </TypingIndicator>
                )}
                <div ref={messagesEndRef} />
            </MessagesContainer>
            <InputContainer>
                <TextInput
                    ref={inputRef}
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && !isTyping && sendMessage()}
                />
                <SendButton onClick={sendMessage} disabled={isTyping}>Gönder</SendButton>
            </InputContainer>
        </ChatContainer>
    );
};

export default Chatbot;
